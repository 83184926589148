.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -30px;
}
div.swiper {
  overflow: visible;
}
div.swiper .swiper-pagination-bullet-active {
  background: var(--ion-color-medium-contrast);
  border: none;
}
div.swiper .swiper-pagination-bullet {
  border: 2px solid var(--ion-color-medium-contrast);
  margin: 0 2px !important;
}
