@tailwind base;
@tailwind components;
@tailwind utilities;

ion-button {
  text-transform: none;
  font-weight: bold;
}

.psx-1{
  --padding-start: 5px;
  --padding-end: 5px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}