// MODALS
ion-modal.select-modal {
  --background: rgba(0, 0, 0, 0.4);
}

ion-modal.swipe-modal {
  --border-radius: 1.25em;

  &::part(content) {
    // background-color: pink;
    padding-top: 52px;
  }
  &::part(handle) {
    background: var(--ion-color-secondary);
    top: 25px;
    width: 80px;
  }
}

.bottom-modal {
  --max-height: 80vh;
  &::part(content) {
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    width: 100%;
  }
  &::part(handle) {
    background: var(--ion-color-secondary);
    top: 25px;
    width: 80px;
  }
}

* {
  color: var(--ion-text-color);
}

ion-tab-button.tab-btn {
  max-width: 100%;
}

ion-toggle {
  --background: var(--ion-color-medium-contrast);
}

ion-header {
  &::after {
    content: none !important;
    background-color: transparent;
    background-image: none;
  }
}
// .searchbar-input.sc-ion-searchbar-md {
//   padding-top: 3px;
//   padding-bottom: 3px;
// }

.swiper-container {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  // CSS Grid/Flexbox bug size workaround
  // @see https://github.com/kenwheeler/slick/issues/982
  // @see https://github.com/nolimits4web/swiper/issues/3599
  min-height: 0;
  min-width: 0;
}

.native-input {
  height: 100%;
}

.toast-over-bottom-menu {
  --ion-safe-area-bottom: 80px;
}
.save-toast {
  --color: #ffffff;
  &::part(icon) {
    color: white;
    font-size: 2.2em;
    // animation: rotation 1s linear infinite;
    // transform-origin: center;
  }
  &::part(message) {
    color: #ffffff;
    font-weight: 600;
  }
  &::part(header) {
    color: #ffffff;
    font-weight: 900;
  }
  &::part(container) {
    bottom: 100px;
  }
  &::part(button) {
    color: #ffffff;
    // font-size: large;
  }
}

.loader {
  width: 15px;
  height: 15px;
  margin: 0 15px;
  padding: 0;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
}
ion-skeleton-text {
  --border-radius: 20px;
  --background: rgb(73 16 126 / 75%);
  --background-rgb: 73, 16, 126;
  &.secondary {
    --border-radius: 20px;
    --background: rgba(0, 222, 218, 0.75);
    --background-rgb: 0, 222, 218;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
