.input-container {
  background-color: var(--ui-light-grey);
  height: 44px;
  border-radius: 15px;
  position: relative;
  color: var(--ion-color-primary);
  margin: auto 0;
  max-width: 400px;
  border: solid 1px transparent;
  
  &.disabled {
    background-color: var(--ion-color-primary-contrast);
    .icon {
      color:var(--ion-color-medium);
    }

  }
  &.focused {
    border: solid 1px var(--ion-color-primary);
    .icon {
      color: var(--ion-color-primary);
    }
  }
  &.error{
    border: solid 1px var(--ui-error);
  }

  .icon{
    width: 43px;
    margin-left: -1rem;
    height: 20px;
    font-size: 20px;
    color: var(--ion-color-medium);;
  }
  .label {
    position: absolute;
    left: 20px;
    width: 100%;
    z-index: 10;
    font-size: 14px;
    color: var(--ion-color-medium);
    pointer-events: none;
    border-radius: 8px;
    transition: all 0.2s ;
    &.filled, &.focused {
      position: absolute;
      display: block;
      top: 0;
      left: 30px;
      padding: 2px 12px;
      background-color: var(--ion-color-primary-contrast);
      font-size: 10px;
      transform: translateY(-50%);
      width: auto;
      color: var(--ion-color-primary);

    }
    &.icon:not(&.focused):not(&.filled) {
      left: calc(43px + 1rem);
    }
   
  }
  .status {
    font-size: 20px;
    .error {
      color:var(--ui-error);
    }
    .success {
      color:var(--ui-success-secondary);
    }
  }

  ion-input{
    --color: var(--ion-color-primary);
    --placeholder-color:var(--ion-color-medium);
    --placeholder-opacity: 1;
    --padding-bottom: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    --padding-top: 0px;
  }
  ion-select {
    --padding-bottom: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    --padding-top: 0px;
  }
}
.invisible-input-btn{
  background-color: transparent;
  border: 0;
  color: var(--ion-color-primary);
  // width: 100%;
  min-height: 25px;
  text-align: left;
}
.flag{
  display: inline-block;
  max-width: 20px;
  margin-right: 10px;
}
.countries-list {
  max-height: calc(80vh - 150px);
  padding-bottom: 10px;
}
ion-modal.input-modal {
  --width: 90vw;
  --min-width: 250px;
  --height: 80vh;
  --max-height: 80vh;
  --min-height: 50vh;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --overflow: hidden;
  color: var(--ion-color-primary);
  .modal-wrapper, #ion-react-wrapper {
    display: block;
    max-height: 80vh;
  }
}

ion-modal.calendar-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  color: var(--ion-color-primary);
}

.PhoneInputInput{
  background: transparent;
  margin-left: 10px;
  border:0;
  outline: none;
}

